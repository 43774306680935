.report {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5em;
}

.report__progress {
  flex: 1 0 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5em;
  align-items: end;
  margin-bottom: 0.4em;
}

.report__bar {
  width: 100%;
  height: 3px;
  margin-bottom: 0.1em;
  overflow: hidden;
  position: relative;
}

.report__bar::before,
.report__bar::after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: var(--color-accent);
  transform-origin: left center;
  transition: all ease 50ms;
}

.report__bar.is-revert::after {
  transform-origin: right center;
}

.report__bar::before {
  opacity: 0.35;
}

.report__bar::after {
  transform: scaleX(var(--val));
}

.report__btn {
  background-color: var(--color-accent);
  color: var(--color-accent-contrast);
  width: 1.1em;
  height: 1.25em;
  font-size: 1.1em;
  line-height: 1;
  margin-right: 0.3em;
  text-align: center;
  display: inline-block;
}

.report__btn svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.report_phase {
  color: var(--color-accent);
  display: flex;
  align-items: center;
}

.report_phase span + span {
  margin-left: 0.3em;
}

.report__icons {
  display: flex;
}

.report__icons svg {
  width: 1.3em;
  height: 1.3em;
  margin-left: 0.3em;
}

.report__log {
  flex: 1 0 100%;
  text-align: left;
  margin-top: 0.7em;
  font-size: 0.7em;
  display: flex;
  white-space: nowrap;
}

.report__log .log + .log {
  margin-left: 2em;
}
