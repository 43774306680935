.setup__hero {
  font-size: 1.8em;
}

.setup__hero:nth-last-child(n + 2),
.setup__hero:nth-last-child(n + 2) ~ .setup__hero {
  font-size: 1.4em;
}

.setup__scenario {
  font-size: 2em;
  margin-bottom: 0.3em;
}

.setup__scenario-info {
  margin-bottom: 0.4em;
}
