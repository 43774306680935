.counter__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.2em;
  position: relative;
}

.counter__wrapper + .counter__wrapper {
  margin-top: 0.8em;
}

.use-compact .counter__wrapper + .counter__wrapper {
  margin-top: 0.2em;
}

.counter {
  font-size: 20px;
  display: grid;
  grid-template-columns: 2.2em 2.2em 32% 2.2em 2.2em;
  grid-template-areas: "prev reduce value add next";
  grid-gap: 0.5em;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 359px) {
  .counter {
    font-size: 14px;
    grid-template-columns: 2.2em 2.2em 2.2em 2.2em;
    grid-template-areas:
      "value value value value"
      "prev reduce add next";
    margin-bottom: 1em;
  }
}

@media (max-width: 559px) {
  .counter {
    font-size: 16px;
  }
}

.counter:not(:last-child) {
  margin-bottom: 0.5em;
}

.counter__title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.4em;
  text-transform: capitalize;
  flex-wrap: wrap;
}

.counter__value {
  white-space: nowrap;
  grid-area: value;
  font-size: 1.75em;
  text-align: center;
}

.counter__value small {
  opacity: 0.5;
}

.counter__next {
  grid-area: next;
}
.counter__prev {
  grid-area: prev;
}
.counter__add {
  grid-area: add;
}
.counter__reduce {
  grid-area: reduce;
}

.counter__btn {
  background-color: var(--color-cta);
  color: var(--color-cta-contrast);
  width: 1.1em;
  height: 1.2em;
  font-size: 2em;
  line-height: 1;
  position: relative;
  text-align: center;
}

.counter.is-ally .counter__btn.is-danger,
.counter.is-hero .counter__btn.is-danger,
.counter.is-minion .counter__btn.is-danger,
.counter.is-villain .counter__btn.is-danger {
  --color-cta: #e06614;
  --color-cta-contrast: #fff;
}

.counter__badge {
  position: absolute;
  color: var(--color-cta);
  background-color: var(--color-cta-contrast);
  border-radius: 100px;
  font-size: 0.5em;
  width: 1.2em;
  height: 1.2em;
  line-height: 1.1;
  text-align: center;
  display: block;
  bottom: 0;
  right: 0;
  border: 2px solid var(--color-cta);
}

.counter__warning {
  position: absolute;
  bottom: 30%;
  width: 100%;
  height: 100%;
  left: 50%;
  color: var(--color-accent);
  z-index: 100;
  filter: drop-shadow(1px 2px 0 var(--color-bg-card));
}

.counter__btn svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.counter__icons {
  position: absolute;
  right: 0;
  filter: drop-shadow(2px 2px 0px var(--color-bg-card));
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: -0.5em;
  transform: translateX(50%);
}

.counter__icons svg {
  width: 2em;
  height: 2em;
  display: block;
}
