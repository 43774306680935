:root {
  --color-accent: #e01414;
  --color-accent-contrast: #fff;
  --color-bg-card: #fff;
  --color-bg: #ddd;
  --color-bg-contrast: #333;
  --color-cta-contrast: #fff;
  --color-cta: #000;
  --color-fg: #000;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: var(--color-bg);
  color: var(--color-fg);
  font-family: "Exo 2", sans-serif;
  font-variant: small-caps;
  margin: 0;
  padding: 1em 1em 15em;
  text-align: center;
  user-select: none;
  transition: all ease 300ms;
}

body.is-hero-phase {
  --color-bg: hsl(207, 82%, 92%);
}

body.is-villain-phase {
  --color-bg: hsl(360, 84%, 94%);
}

body.no-scroll {
  overflow: hidden;
}

main {
  margin: auto;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 1em 0;
}

legend {
  margin-bottom: 0.2em;
}

.is-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.is-aggression {
  --color-accent: #e01414;
  --color-accent-contrast: #fff;
}

.is-minion,
.is-tough,
.is-villain {
  --color-accent: #e06614;
}

.is-scenario,
.is-side-scheme,
.is-scheme,
.is-justice {
  --color-accent: #f2ca00;
  --color-accent-contrast: #000;
}

.is-protection,
.is-stunned,
.is-scheme-win,
.is-winner {
  --color-accent: #00cc4e;
}

.is-ally,
.is-extra,
.is-hero,
.is-leadership {
  --color-accent: #3499eb;
}

.is-confused {
  --color-accent: #9b069b;
  --color-accent-contrast: #fff;
}

button {
  align-items: center;
  background-color: var(--color-cta);
  border: 0;
  box-sizing: border-box;
  color: var(--color-cta-contrast);
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-variant: small-caps;
  justify-content: center;
  margin: 0.3em;
  padding: 0.5em 1em 0.7em;
}

form {
  margin: 0.2em 0;
}

.fraction {
  display: inline-block;
  font-size: 0.6em;
  margin: 0 0.2em;
  opacity: 0.5;
}

body.is-dark {
  --color-bg-card: #000;
  --color-bg: #333;
  --color-bg-contrast: #ddd;
  --color-cta-contrast: #000;
  --color-cta: #fff;
  --color-fg: #fff;
}

.u-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.is-filter {
  color: var(--color-accent);
}

.accent-button {
  --color-cta-contrast: var(--color-accent-contrast);
  --color-cta: var(--color-accent);
}

@media (prefers-color-scheme: dark) {
  body:not(.is-light) {
    --color-bg-card: #000;
    --color-bg: #333;
    --color-bg-contrast: #ddd;
    --color-cta-contrast: #000;
    --color-cta: #fff;
    --color-fg: #fff;
  }

  body:not(.is-light).is-hero-phase {
    --color-bg: hsl(207, 82%, 20%);
  }

  body:not(.is-light).is-villain-phase {
    --color-bg: hsl(360, 84%, 20%);
  }
}
