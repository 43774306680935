.option {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  padding: 0.2em 0;
}

.option--inline {
  display: inline-flex;
}

.option--inline + .option--inline {
  margin-left: 0.5em;
}

.option__flag {
  margin-left: 0.5em;
  opacity: 0.6;
}

.option input.is-hidden {
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
}
