.box {
  position: relative;
  text-align: center;
  max-width: 500px;

  margin: 0 auto;
  padding: 2.5em 0 1em;
  width: 100%;
}

.use-compact .box {
  padding-top: 0.5em;
  padding-bottom: 0;
}

.box--flag {
  text-align: left;
}

.box__wrapper {
  display: grid;
  justify-content: space-around;
  grid-gap: 0 1em;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

.box__title {
  position: relative;
  background-color: var(--color-bg-card);
  font-size: 1.4em;
  padding: 0 0.6em;
  line-height: 1.05;
  text-transform: uppercase;
  border-left: 4px solid var(--color-accent);
  width: max-content;
}

.use-compact .box__title {
  top: 0.25em;
  position: static;
  font-size: 1em;
  transform: none;
  max-width: 100%;
  box-shadow: 0 0 15px #0003;
}

.box__title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box__highlight {
  position: absolute;
  margin-left: -12px;
  left: 100%;
  bottom: 50%;
  width: 2em;
  height: 2em;
}

.use-compact .box__highlight {
  width: 1.75em;
  height: 1.75em;
  bottom: 100%;
  left: 100%;
  transform: translateY(110%);
}

.box__content {
  background-color: var(--color-bg-card);
  box-shadow: 0 0 15px #0003;
  padding: 0.8em;
  font-size: 1.1em;
  border-left: 4px solid var(--color-accent);
  position: relative;
}

.use-compact .box__content {
  transform: none;
  padding: 0.2em 0.5em;
}

.box--flat .box__content {
  transform: none;
}

.box.is-log .box__content {
  max-height: 40vh;
  overflow: auto;
}

.box__legend {
  margin-top: 1.5em;
}

.box__band {
  background: var(--color-accent);
  color: var(--color-accent-contrast);
  font-size: 1.4em;
  margin: 0.8em -0.56em;
  padding: 0.2em 0.3em;
  text-transform: uppercase;
  white-space: nowrap;
}
